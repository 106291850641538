<template>
	<div>
		<v-row class="d-flex justify-space-between" style="background-color: #f5f5f5 !important">
			<!--
				Everyone can see now  
				v-if="getPermission('overview_about:view')" -->
			<v-col md="12" style="display: grid; grid-template-columns: 3fr 5fr">
				<div class="elevation-3 rounded-lg mx-2 bg-white">
					<div
						:style="{
							backgroundColor: '#f64e60',
							borderTopLeftRadius: '7px',
							borderTopRightRadius: '7px',
						}"
						class="d-flex align-items-center pa-2 border-bottom"
					>
						<div>
							<span class="font-level-4-bold white--text"> About </span>
						</div>
						<v-spacer></v-spacer>
						<!-- <v-icon color="#0D47A1" v-on:click.stop.prevent="aboutDialog = true" small>edit</v-icon> -->
						<template
							v-if="
								ANDEvery([
									!pageLoading,
									usersType == 'accounts' || getValue(Login_User, 'is_master') == 1,
									getPermission('overview_about:create') || getPermission('overview_about:update'),
									AllowAnyOforGetRoleNType([
										'master',
										'admin',
										'manager',
										'super_admin',
										'team_lead',
										'ateam_lead',
									]),
								])
							"
						>
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<v-icon
										color="#fff"
										v-on="on"
										v-bind="attrs"
										v-on:click="edit(data.about, 'about')"
										size="20"
										:disabled="pageLoading"
									>
										edit
									</v-icon>
								</template>
								<span>Edit About</span>
							</v-tooltip>
						</template>

						<!-- <v-tooltip top>
							<template #activator="{ on, attrs }">	
								<v-icon
									color="#0D47A1"
									v-on="on"
									v-bind="attrs"
									v-on:click="edit(data.about, 'about')"
									>
									add
								</v-icon>
							</template>
							<span>Add About</span>
						</v-tooltip> -->
						<!-- <v-icon @click="about_tab = !about_tab">{{
							about_tab ? "mdi-menu-down" : "mdi-menu-right"
						}}</v-icon> -->
					</div>
					<div
						style="
							background-color: #ffebee;
							border-bottom-right-radius: 7px;
							border-bottom-left-radius: 7px;
						"
						class="card--body"
					>
						<table class="w-70">
							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									Business Name
								</td>
								<td class="fw-500 px-3 py-1">
									<!-- <template v-if="domainDetail && domainDetail.business_name">
										{{ domainDetail.business_name }}</template
									>
									<template v-else> <em class="text-muted">no business name</em></template> -->
									<ShowValue
										:object="data.about"
										object-key="business_name"
										label="business name"
									></ShowValue>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									Phone
								</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue :object="data.about" object-key="phone" label="Phone"></ShowValue>
								</td>
							</tr>

							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									Email
								</td>
								<td class="fw-500 px-3 py-1">
									<div class="cursor-pointer">
										<ShowValue
											:object="data.about"
											:capitalize="false"
											object-key="email"
											label="Email"
										></ShowValue>
									</div>
								</td>
							</tr>

							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									Address
								</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue :object="data.about" object-key="address" label="Address"></ShowValue>
								</td>
							</tr>

							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									CMS
								</td>

								<td class="fw-500 px-3 py-1" style="text-transform: capitalize">
									<template v-if="data.about.CMS">
										<v-tooltip left>
											<template #activator="{ on, attrs }">
												<div class="d-flex align-center">
													<inline-svg
														v-if="data.about.CMS == 'wix'"
														style="width: 25px; height: 25px"
														:src="$assetURL('media/custom-svg/wix-cms.svg')"
														v-on="on"
														v-bind="attrs"
													/>
													<inline-svg
														v-else-if="data.about.CMS == 'wordpress'"
														style="width: 25px; height: 25px"
														:src="$assetURL('media/custom-svg/wordpress-cms.svg')"
														v-on="on"
														v-bind="attrs"
													/>
													<inline-svg
														v-else-if="data.about.CMS == 'shopify'"
														style="width: 25px; height: 25px"
														:src="$assetURL('media/custom-svg/shopify-cms.svg')"
														v-on="on"
														v-bind="attrs"
													/>
													<p class="mb-0 ml-1 text-capitalize">
														{{ data.about.CMS == "other" ? data.about.other_cms : data.about.CMS }}
													</p>
												</div>
											</template>
											<span class="text-capitalize">{{ data.about.CMS }}</span>
										</v-tooltip>
									</template>
									<template v-else>
										<em class="text-muted text-lowercase">no cms</em>
									</template>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									Account Country
								</td>
								<td class="fw-500 px-3 py-1 d-flex align-center">
									<div
										v-if="
											data.about && data.about.account_country && country_flag[data.about.account_country]
										"
										class="vti__flag mr-2"
										:class="country_flag[data.about.account_country]"
									/>
									<ShowValue
										:object="data.about"
										object-key="account_country"
										label="Account Country"
									></ShowValue>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									Category
								</td>
								<td class="fw-500 px-3 py-1 d-flex align-center">
									<template v-if="data.about && data.about.category">{{
										mod_string(data.about.category, "_", " ")
									}}</template>
									<template v-else><em class="text-muted">no Category</em></template>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									Domain
								</td>
								<td class="fw-500 px-3 py-1 d-flex align-center">
									<!-- <pre>{{ urlData }}</pre> -->
									<ShowValue
										:object="urlData"
										object-key="website_urls"
										:capitalize="false"
										label="Domain URL"
									></ShowValue>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 150px !important; width: 150px !important">
									Target Country
								</td>
								<td class="fw-500 px-3 py-1 d-flex align-center">
									<!-- url_target_google.text
									other_target_google -->
									<!-- <div
										v-if="target && target.url_target_google && target.url_target_google.text != 'other'"
										class="vti__flag mr-2"
										:class="country_flag[target && target.url_target_google && target.url_target_google.text]"
									/> -->
									<span
										v-if="
											urlData &&
											(urlData.other_target_google ||
												(urlData.url_target_google && urlData.url_target_google.id))
										"
										>{{
											(urlData.url_target_google.value == "other" || urlData.url_target_google.id == 216) &&
											urlData.other_target_google
												? urlData.other_target_google
												: urlData.url_target_google.text
										}}</span
									>
									<em v-else class="text-muted">no target country</em>
									<!-- <ShowValue :object="{ target }" object-key="target" label="Account Country"></ShowValue> -->
								</td>
							</tr>
						</table>
					</div>
				</div>

				<div class="bg-white elevation-3 mx-2 rounded-lg">
					<div
						class="d-flex align-items-center pa-2"
						:style="{
							backgroundColor: '#1bc5bd',
							borderTopLeftRadius: '7px',
							borderTopRightRadius: '7px',
						}"
					>
						<div class="font-level-4-bold white--text">Promised KPI</div>
						<!-- <v-spacer></v-spacer> -->

						<!-- <v-icon @click="promised_kpi = !promised_kpi">{{
							promised_kpi ? "mdi-menu-down" : "mdi-menu-right"
						}}</v-icon> -->
					</div>
					<!-- <v-row>
						<v-col md="12">
							<v-row>
								<v-col md="6">
									<div class="font-level-3-bold grey lighten-3 pl-1 py-1">To Customer</div>
									<v-row>
										<v-col md="4"></v-col>
										<v-col md="4"><th class="fw-600 text-center">Promised</th></v-col>
										<v-col md="4"><th class="fw-600 text-center">Current</th></v-col>
									</v-row>
									<v-row v-for="(top, index) in promisedkey['toCustomer']" :key="index" :class="{'alternate-row': index % 2 !== 0}">
										<v-col md="4">{{ top.title }}</v-col>
										<v-col md="4">{{ top.value }}</v-col>
										<v-col md="4">{{ top.current }}</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
					</v-row> -->
					<div class="card--body px-3" style="min-height: 100%">
						<v-row class="d-flex justify-space-between">
							<v-col
								:md="
									(getPermission('promised_kpi_customer:view') &&
										AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])) ||
									usersType == 'accounts'
										? 6
										: 12
								"
								v-if="
									(getPermission('promised_kpi_customer:view') &&
										AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])) ||
									usersType == 'accounts'
								"
								class="promised-kpi-table"
							>
								<div
									class="d-flex justify-content-between py-1 px-2"
									:style="{ backgroundColor: '#fff4de', borderRadius: '7px' }"
								>
									<div class="font-level-3-bold fw-600">To Customer</div>
									<template
										v-if="
											!pageLoading &&
											getValue(Login_User, 'is_master') == 1 &&
											(getPermission('promised_kpi_customer:create') ||
												getPermission('promised_kpi_customer:update'))
										"
									>
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<v-icon
													color="orange darken-4"
													v-on="on"
													v-bind="attrs"
													v-on:click="edit(data.kpi['toCustomer'], 'kpi', 1)"
													small
													:disabled="pageLoading"
													>edit</v-icon
												>
											</template>
											<span>Edit To Customer</span>
										</v-tooltip>
									</template>
								</div>
								<table class="mt-2" style="width: 100%">
									<thead>
										<tr>
											<th class="fw-600"></th>
											<th colspan="2" class="fw-600 text-center">Promised</th>
											<th class="fw-600 text-center">Current</th>
										</tr>
										<tr>
											<th></th>
											<th class="fw-600 text-center">6 Month</th>
											<th class="fw-600 text-center">12 Month</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td class="fw-600 px-3 py-2">Top 3</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.kpi_6_month_top_3")
														? getValue(data, "kpi.toCustomer.kpi_6_month_top_3")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.top_3") ? getValue(data, "kpi.toCustomer.top_3") : "-"
												}}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													getValue(data, 'kpi.toCustomer.kpi_6_month_top_3')
														? kpiColorIdentifier(
																getValue(data, 'kpi.toCustomer.kpi_6_month_top_3'),
																getValue(data, 'kpi.toCustomer.top_3'),
																getValue(current_rank, 'top_3')
														  )
														: '',
												]"
											>
												{{ getValue(current_rank, "top_3") ? getValue(current_rank, "top_3") : "-" }}
											</td>
										</tr>
										<tr class="promised-kpi-table-tr">
											<td class="fw-600 px-3 py-2">Top 5</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.kpi_6_month_top_5")
														? getValue(data, "kpi.toCustomer.kpi_6_month_top_5")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.top_5") ? getValue(data, "kpi.toCustomer.top_5") : "-"
												}}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toCustomer.kpi_6_month_top_5'),
														getValue(data, 'kpi.toCustomer.top_5'),
														getValue(current_rank, 'top_5')
													),
												]"
											>
												{{ getValue(current_rank, "top_5") ? getValue(current_rank, "top_5") : "-" }}
											</td>
										</tr>
										<tr>
											<td class="fw-600 px-3 py-2">Top 10</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.kpi_6_month_top_10")
														? getValue(data, "kpi.toCustomer.kpi_6_month_top_10")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.top_10") ? getValue(data, "kpi.toCustomer.top_10") : "-"
												}}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toCustomer.kpi_6_month_top_10'),
														getValue(data, 'kpi.toCustomer.top_10'),
														getValue(current_rank, 'top_10')
													),
												]"
											>
												{{ getValue(current_rank, "top_10") ? getValue(current_rank, "top_10") : "-" }}
											</td>
										</tr>
										<tr class="promised-kpi-table-tr">
											<td class="fw-600 px-3 py-2">Top 20</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.kpi_6_month_top_20")
														? getValue(data, "kpi.toCustomer.kpi_6_month_top_20")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.top_20") ? getValue(data, "kpi.toCustomer.top_20") : "-"
												}}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toCustomer.kpi_6_month_top_20'),
														getValue(data, 'kpi.toCustomer.top_20'),
														getValue(current_rank, 'top_20')
													),
												]"
											>
												{{ getValue(current_rank, "top_20") ? getValue(current_rank, "top_20") : "-" }}
											</td>
										</tr>
										<tr>
											<td class="fw-600 px-3 py-2">Top 50</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.kpi_6_month_top_50")
														? getValue(data, "kpi.toCustomer.kpi_6_month_top_50")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.top_50") ? getValue(data, "kpi.toCustomer.top_50") : "-"
												}}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toCustomer.kpi_6_month_top_50'),
														getValue(data, 'kpi.toCustomer.top_50'),
														getValue(current_rank, 'top_50')
													),
												]"
											>
												{{ getValue(current_rank, "top_50") ? getValue(current_rank, "top_50") : "-" }}
											</td>
										</tr>
										<tr class="promised-kpi-table-tr">
											<td class="fw-600 px-3 py-2">Top 100</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.kpi_6_month_top_100")
														? getValue(data, "kpi.toCustomer.kpi_6_month_top_100")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toCustomer.top_100")
														? getValue(data, "kpi.toCustomer.top_100")
														: "-"
												}}
											</td>
											<td
												class="fw-500 px-3 py-1 red--text text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toCustomer.kpi_6_month_top_100'),
														getValue(data, 'kpi.toCustomer.top_100'),
														getValue(current_rank, 'top_100')
													),
												]"
											>
												{{ getValue(current_rank, "top_100") ? getValue(current_rank, "top_100") : "-" }}
											</td>
										</tr>
									</tbody>
								</table>
							</v-col>

							<v-col
								v-if="
									getPermission('promised_kpi_delhi:view') ||
									getValue(Login_User, 'users_type') == 'accounts'
								"
								:md="
									(getPermission('promised_kpi_customer:view') &&
										AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])) ||
									usersType == 'accounts'
										? 6
										: 12
								"
								class="promised-kpi-table"
							>
								<div
									class="d-flex justify-content-between py-1 px-2"
									:style="{ backgroundColor: '#c9f7f5', borderRadius: '7px' }"
								>
									<div class="font-level-3-bold fw-600">To Delhi</div>
									<template
										v-if="
											!pageLoading &&
											getValue(Login_User, 'is_master') == 1 &&
											(getPermission('promised_kpi_delhi:create') ||
												getPermission('promised_kpi_delhi:update')) &&
											AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
										"
									>
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<v-icon
													color="cyan darken-3"
													v-on="on"
													v-bind="attrs"
													v-on:click="edit(data.kpi['toDelhi'], 'kpi', 2)"
													small
													:disabled="pageLoading"
													>edit</v-icon
												>
											</template>
											<span>Edit To Delhi</span>
										</v-tooltip>
									</template>
								</div>
								<table class="mt-2" style="width: 100%">
									<thead>
										<tr>
											<th class="fw-600"></th>
											<th colspan="2" class="fw-600 text-center">Promised</th>
											<th class="fw-600 text-center">Current</th>
										</tr>
										<tr>
											<th></th>
											<th class="fw-600 text-center">6 Month</th>
											<th class="fw-600 text-center">12 Month</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td class="fw-600 px-3 py-2">Top 3</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toDelhi.kpi_6_month_top_3")
														? getValue(data, "kpi.toDelhi.kpi_6_month_top_3")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{ getValue(data, "kpi.toDelhi.top_3") ? getValue(data, "kpi.toDelhi.top_3") : "-" }}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toDelhi.kpi_6_month_top_3'),
														getValue(data, 'kpi.toDelhi.top_3'),
														getValue(current_rank, 'top_3')
													),
												]"
											>
												{{ getValue(current_rank, "top_3") ? getValue(current_rank, "top_3") : "-" }}
											</td>
										</tr>
										<tr class="promised-kpi-table-tr">
											<td class="fw-600 px-3 py-2">Top 5</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toDelhi.kpi_6_month_top_5")
														? getValue(data, "kpi.toDelhi.kpi_6_month_top_5")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{ getValue(data, "kpi.toDelhi.top_5") ? getValue(data, "kpi.toDelhi.top_5") : "-" }}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toDelhi.kpi_6_month_top_5'),
														getValue(data, 'kpi.toDelhi.top_5'),
														getValue(current_rank, 'top_5')
													),
												]"
											>
												{{ getValue(current_rank, "top_5") ? getValue(current_rank, "top_5") : "-" }}
											</td>
										</tr>
										<tr>
											<td class="fw-600 px-3 py-2">Top 10</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toDelhi.kpi_6_month_top_10")
														? getValue(data, "kpi.toDelhi.kpi_6_month_top_10")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{ getValue(data, "kpi.toDelhi.top_10") ? getValue(data, "kpi.toDelhi.top_10") : "-" }}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toDelhi.kpi_6_month_top_10'),
														getValue(data, 'kpi.toDelhi.top_10'),
														getValue(current_rank, 'top_10')
													),
												]"
											>
												{{ getValue(current_rank, "top_10") ? getValue(current_rank, "top_10") : "-" }}
											</td>
										</tr>
										<tr class="promised-kpi-table-tr">
											<td class="fw-600 px-3 py-2">Top 20</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toDelhi.kpi_6_month_top_20")
														? getValue(data, "kpi.toDelhi.kpi_6_month_top_20")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{ getValue(data, "kpi.toDelhi.top_20") ? getValue(data, "kpi.toDelhi.top_20") : "-" }}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toDelhi.kpi_6_month_top_20'),
														getValue(data, 'kpi.toDelhi.top_20'),
														getValue(current_rank, 'top_20')
													),
												]"
											>
												{{ getValue(current_rank, "top_20") ? getValue(current_rank, "top_20") : "-" }}
											</td>
										</tr>
										<tr>
											<td class="fw-600 px-3 py-2">Top 50</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toDelhi.kpi_6_month_top_50")
														? getValue(data, "kpi.toDelhi.kpi_6_month_top_50")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{ getValue(data, "kpi.toDelhi.top_50") ? getValue(data, "kpi.toDelhi.top_50") : "-" }}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toDelhi.kpi_6_month_top_50'),
														getValue(data, 'kpi.toDelhi.top_50'),
														getValue(current_rank, 'top_50')
													),
												]"
											>
												{{ getValue(current_rank, "top_50") ? getValue(current_rank, "top_50") : "-" }}
											</td>
										</tr>
										<tr class="promised-kpi-table-tr">
											<td class="fw-600 px-3 py-2">Top 100</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toDelhi.kpi_6_month_top_100")
														? getValue(data, "kpi.toDelhi.kpi_6_month_top_100")
														: "-"
												}}
											</td>
											<td class="fw-500 px-3 py-1 text-center">
												{{
													getValue(data, "kpi.toDelhi.top_100") ? getValue(data, "kpi.toDelhi.top_100") : "-"
												}}
											</td>
											<td
												class="fw-500 px-3 py-1 text-center"
												:class="[
													kpiColorIdentifier(
														getValue(data, 'kpi.toDelhi.kpi_6_month_top_100'),
														getValue(data, 'kpi.toDelhi.top_100'),
														getValue(current_rank, 'top_100')
													),
												]"
											>
												{{ getValue(current_rank, "top_100") ? getValue(current_rank, "top_100") : "-" }}
											</td>
										</tr>
									</tbody>
								</table>
							</v-col>
						</v-row>
					</div>
				</div>
			</v-col>

			<!-- v-if="getPermission('promised_kpi_customer:view') || getPermission('promised_kpi_delhi:view')" -->

			<!-- v-if="getPermission('overview_contract:view')" -->
			<v-col md="12" style="display: grid; grid-template-columns: repeat(2, 1fr)">
				<div class="bg-white mx-2 elevation-3 rounded-lg">
					<div
						@click="contract_tab = !contract_tab"
						class="d-flex align-items-center pa-2 border-bottom"
					>
						<div class="font-level-4-bold fw-600">Contract</div>
						<!-- <v-spacer></v-spacer>
						<v-icon>{{ contract_tab ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon> -->
					</div>
					<div style="min-height: 100%">
						<table class="w-70">
							<tr>
								<td class="fw-600 px-3 py-2">Start Date</td>
								<td class="fw-500 px-3 py-1">
									<template v-if="detail && detail.select_date">
										{{ formatDate(detail.select_date) }}</template
									>
									<template v-else> <em class="text-muted">no start date</em></template>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2">End Date</td>
								<td class="fw-500 px-3 py-1">
									<template v-if="detail && (detail.time_free_end || detail.total_time_end)">
										{{ formatDate(detail.time_free_end || detail.total_time_end) }}</template
									>
									<template v-else> <em class="text-muted">no end date</em></template>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 160px">Reporting Date</td>
								<td class="fw-500 px-3" style="min-width: 160px" v-clickoutside="clickoutside">
									<div class="py-3 d-flex justify-space-between">
										<!-- <v-date-picker placeholder="Reporting Date"></v-date-picker> -->
										<!-- <DatePicker
												v-if="reporting_edit == true"
												:hideTopMargin="true"
												depressed
												hidePrefixIcon
												placeholder="Reporting Date"
												v-model="detail.reporting_date"
												:inline-style="{ maxWidth: '151px' }"
												@change="() => update_reporting_date()"
											></DatePicker> -->
										<SelectInput
											hide-details
											customClass="mt-0"
											v-if="reporting_edit == true"
											:disabled="pageLoading"
											:loading="pageLoading"
											:items="[1, 15]"
											id="reporting_date"
											v-model="detail.reporting_date"
											:menu-props="{ offsetY: true }"
											@change="update_reporting_date"
											noStyleItem="item"
											:inlineStyle="{ maxWidth: '80px' }"
										>
										</SelectInput>
										<template v-else>
											<div v-if="detail && detail.reporting_date" class="my-auto">
												{{ detail.reporting_date }}
											</div>
											<template v-else> <em class="text-muted">no reporting date</em></template>
										</template>

										<v-tooltip
											right
											v-if="
												!pageLoading &&
												getValue(Login_User, 'is_master') == 1 &&
												(getPermission('overview_contract:create') ||
													getPermission('overview_contract:update')) &&
												AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
											"
										>
											<template #activator="{ on, attrs }">
												<v-icon
													color="#0D47A1"
													style="padding-top: 7px !important; padding-bottom: 7px !important"
													@click="reporting_edit = true"
													small
													v-on="on"
													v-bind="attrs"
													:disabled="pageLoading"
													>edit
												</v-icon>
											</template>
											<span>Edit Reporting Date</span>
										</v-tooltip>
									</div>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2">Months Passed</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue :object="detail" object-key="month_passed" label="Month passed"></ShowValue>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2">Months Remaining</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue
										:object="detail"
										object-key="month_remaining"
										label="Month Remaining"
									></ShowValue>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="bg-white elevation-3 rounded-lg mx-2">
					<div class="card--header d-flex align-items-center pa-2 border-bottom">
						<div class="font-level-3-bold">Team</div>
						<v-spacer></v-spacer>

						<v-tooltip
							left
							v-if="
								!pageLoading &&
								getValue(Login_User, 'is_master') == 1 &&
								(getPermission('overview_team:create') || getPermission('overview_team:update')) &&
								AllowAnyOforGetRoleNType([
									'master',
									'admin',
									'manager',
									'super_admin',
									'team_lead',
									'ateam_lead',
								])
							"
						>
							<template #activator="{ on, attrs }">
								<v-icon
									color="#0D47A1"
									v-on:click="edit(data.teams, 'teams')"
									small
									v-on="on"
									v-bind="attrs"
									:disabled="pageLoading"
								>
									edit
								</v-icon>
							</template>
							<span>Edit Teams</span>
						</v-tooltip>

						<!-- <v-icon @click="team_tab = !team_tab">{{
							team_tab ? "mdi-menu-down" : "mdi-menu-right"
						}}</v-icon> -->
					</div>
					<div class="card--body pa-2" style="min-height: 100%" v-if="team_tab">
						<table class="w-70">
							<tr>
								<td class="fw-600 px-3 py-2">Sales Owner</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue
										:object="detail?.project?.sales_person"
										object-key="display_name"
										label="Sales Owner"
									></ShowValue>
								</td>
							</tr>
							<tr>
								<td class="fw-600 px-3 py-2">Team Lead</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue :object="data.teams" object-key="team_lead_name" label="Team Lead"></ShowValue>
								</td>
							</tr>

							<tr>
								<td class="fw-600 px-3 py-2">A.Team Lead</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue
										:object="data.teams"
										object-key="a_team_lead_name"
										label="assistance team lead"
									></ShowValue>
								</td>
							</tr>

							<tr>
								<td class="fw-600 px-3 py-2">ON-PAGE</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue :object="data.teams" object-key="on_page_name" label="On Page"></ShowValue>
								</td>
							</tr>

							<tr>
								<td class="fw-600 px-3 py-2">OFF-PAGE</td>
								<td class="fw-500 px-3 py-1">
									<ShowValue :object="data.teams" object-key="off_page_name" label="OFf Page"></ShowValue>
								</td>
							</tr>

							<tr>
								<td class="fw-600 px-3 py-2" style="min-width: 140px">Sales Coordinator</td>
								<td class="fw-500 px-3 py-1" style="min-width: 160px">
									<ShowValue
										:object="data.teams"
										object-key="sales_coordinater_name"
										label="sales coordinator"
									></ShowValue>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</v-col>

			<!-- v-if="getPermission('overview_team:view')" -->

			<!-- v-if="getPermission('overview_competitors:view')" -->
			<!-- v-if="
					!(
						getValue(detail, 'sales_owner_approve') == 0 &&
						getValue(detail, 'account_approve') == 0 &&
						getValue(detail, 'team_lead_approve') == 0
					) &&
					AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead', 'ateam_lead'])
				" -->
			<v-col md="12" class="detail-card" style="display: grid; grid-template-columns: 4fr 3fr">
				<div class="bg-white elevation-3 rounded-lg mx-2">
					<div class="card--header d-flex align-items-center pa-2 border-bottom">
						<div class="font-level-3-bold">Competitors</div>
						<v-spacer></v-spacer>

						<v-tooltip
							left
							v-if="
								!pageLoading &&
								getValue(Login_User, 'is_master') == 1 &&
								(getPermission('overview_competitors:create') ||
									getPermission('overview_competitors:update')) &&
								AllowAnyOforGetRoleNType([
									'master',
									'admin',
									'manager',
									'super_admin',
									'team_lead',
									'ateam_lead',
									'on_page',
								])
							"
						>
							<template #activator="{ on, attrs }">
								<v-icon
									color="#0D47A1"
									v-on:click="edit(data.competitors, 'competitors')"
									small
									:disabled="pageLoading"
									v-on="on"
									v-bind="attrs"
									>edit</v-icon
								>
							</template>
							<span>Edit Competitors</span>
						</v-tooltip>

						<!-- <v-icon @click="competitors_tab = !competitors_tab">{{
							competitors_tab ? "mdi-menu-down" : "mdi-menu-right"
						}}</v-icon> -->
					</div>
					<div class="card--body" style="min-height: 100%" v-if="competitors_tab">
						<table class="w-100">
							<tr v-for="(comp, index) in data?.competitors" :key="'Competetors_' + index">
								<td class="fw-600 text-center px-3 py-2" style="width: 40px !important">{{ index + 1 }}</td>
								<td class="fw-500 px-3 py-1">
									<span>{{ comp }}</span>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<!-- getValue(detail, 'request_status') && -->

				<!-- Approval Status show: not null (1st time) && request_status >= 0 (0 when approved/rejected) && permitted users -->
				<div
					class="elevation-3 rounded-lg mx-2"
					:class="BG_Color[getValue(detail, 'request_status') || 'empty']"
					v-if="
						(getValue(detail, 'request_status') || getValue(detail, 'request_status') == 0) &&
						getValue(detail, 'request_status') >= 0 &&
						(AllowAnyOforGetRoleNType([
							'master',
							'admin',
							'manager',
							'super_admin',
							'team_lead',
							'ateam_lead',
						]) ||
							AllowAnyOforGetRoleNType()?.users_type == 'accounts' ||
							(AllowAnyOforGetRoleNType()?.users_type == 'sales' &&
								+Login_User?.id == +detail.project.sales_person.id))
					"
				>
					<div class="d-flex align-items-center pa-2 border-bottom">
						<div class="font-level-3-bold">
							Approval Status
							<span
								v-if="getValue(detail, 'request_status')"
								class="fw-600"
								:class="[R_Approval_Status_Colors[getValue(detail, 'request_status')]]"
							>
								{{ Requested_Status[getValue(detail, "request_status")] }}
							</span>
							<span v-else :class="[R_Approval_Status_Colors[getValue(detail, 'status')]]">
								{{ Requested_Status[getValue(detail, "status")] }}
							</span>
						</div>
					</div>
					<div class="card--body fw-500 px-3">
						<table class="approval-status-table my-2">
							<thead>
								<tr>
									<th>Role</th>
									<th>Status</th>
									<th>Remark</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Account</td>
									<td :class="[Approval_Status_Colors[getValue(detail, 'account_approve')]]">
										({{ Approval_Status_Title[getValue(detail, "account_approve")] }})
									</td>
									<td>
										{{ getValue(detail, "account_remark") ? getValue(detail, "account_remark") : "-" }}
									</td>
								</tr>
								<tr>
									<td>Sales Owner</td>
									<td :class="[Approval_Status_Colors[getValue(detail, 'sales_owner_approve')]]">
										({{ Approval_Status_Title[getValue(detail, "sales_owner_approve")] }})
									</td>
									<td>
										{{
											getValue(detail, "sales_owner_remark") ? getValue(detail, "sales_owner_remark") : "-"
										}}
									</td>
								</tr>
								<tr>
									<td>Team Lead</td>
									<td :class="[Approval_Status_Colors[getValue(detail, 'team_lead_approve')]]">
										({{ Approval_Status_Title[getValue(detail, "team_lead_approve")] }})
									</td>
									<td>
										{{ getValue(detail, "team_lead_remark") ? getValue(detail, "team_lead_remark") : "-" }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</v-col>
		</v-row>

		<v-row v-if="credentialStatus" style="background-color: #f5f5f5 !important">
			<v-col md="12" class="detail-card mb-3">
				<div class="bg-white elevation-3 rounded-lg mx-2">
					<div class="card--header d-flex align-items-center pa-2 border-bottom">
						<div class="font-level-3-bold">Credentials</div>
						<v-spacer></v-spacer>
						<template
							v-if="
								!pageLoading &&
								getValue(Login_User, 'is_master') == 1 &&
								(getPermission('overview_credentials:create') ||
									getPermission('overview_credentials:update'))
							"
						>
							<v-tooltip left>
								<template #activator="{ on, attrs }">
									<v-btn
										v-on="on"
										v-bind="attrs"
										tile
										:disabled="addCredentialsStatus.status || pageLoading"
										depressed
										color="blue darken-4"
										class="text-white"
										@click="edit({}, 'credential')"
									>
										<!-- :disabled="pageLoading" -->
										<v-icon left> mdi-plus </v-icon>
										Add
									</v-btn>
								</template>
								<span>Add Credentials</span>
							</v-tooltip>
						</template>
						<!-- <v-icon @click="credentials_tab = !credentials_tab">{{
							credentials_tab ? "mdi-menu-down" : "mdi-menu-right"
						}}</v-icon> -->
					</div>
					<div v-if="credentials_tab" class="card--body pa-2" style="min-height: 100%">
						<table width="100%" class="table-head-sticky">
							<thead>
								<tr>
									<th class="p-2 custome-table" style="background: #f0f8ff00 !important" width="100">
										Type
									</th>
									<th style="background: #f0f8ff00 !important" class="p-2 light-blue-bg custome-table">
										Email
									</th>
									<th style="background: #f0f8ff00 !important" class="p-2 light-blue-bg custome-table">
										Username
									</th>
									<th style="background: #f0f8ff00 !important" class="p-2 light-blue-bg custome-table">
										Password
									</th>
									<th style="background: #f0f8ff00 !important" class="p-2 light-blue-bg custome-table">
										Last Updated By
									</th>
									<th style="background: #f0f8ff00 !important" class="p-2 light-blue-bg custome-table">
										Date
									</th>
									<th
										width="100px"
										style="background: #f0f8ff00 !important"
										class="p-2 light-blue-bg custome-table"
										v-if="
											usersType == 'seo' ||
											(getValue(Login_User, 'is_master') == 1 &&
												(getPermission('overview_credentials:create') ||
													getPermission('overview_credentials:update')))
										"
									>
										Actions
									</th>
								</tr>
							</thead>

							<tbody>
								<template v-if="data.credential.length > 0">
									<tr v-for="(credential, index) in data.credential" :key="`_list_data_${index}`">
										<td
											class="p-2 border-top-light-grey custome-table-td"
											style="border-top: solid 1px #e2e5ec !important; font-weight: 700"
										>
											<ShowValue :object="credential" object-key="type" label="page"></ShowValue>
										</td>
										<td class="p-2 border-top-light-grey custome-table-td">
											<ShowValue
												:object="credential"
												object-key="email"
												label="email"
												:clickToCopy="true"
												:capitalize="false"
											></ShowValue>
										</td>
										<td class="p-2 border-top-light-grey custome-table-td">
											<ShowValue
												:object="credential"
												object-key="username"
												label="username"
												:capitalize="false"
											></ShowValue>
										</td>
										<td class="p-2 border-top-light-grey custome-table-td">
											<ShowValue
												:object="credential"
												object-key="password"
												label="password"
												:capitalize="false"
											></ShowValue>
										</td>
										<td class="p-2 border-top-light-grey custome-table-td">
											<v-chip v-if="getValue(credential, 'updated_by')" color="green darken-4" outlined>
												<ShowValue :object="credential" object-key="updated_by" label="updated"></ShowValue>
											</v-chip>
											<v-chip v-else-if="getValue(credential, 'added_by')" color="green darken-4" outlined>
												<ShowValue :object="credential" object-key="added_by" label="updated"></ShowValue>
											</v-chip>
											<em v-else>no added/updated by</em>
											<!-- <p class="py-0 mb-1">
											</p> -->
											<!-- <p v-if="credential.access"  class="py-0 mb-0">
												<v-chip v-if="credential.type" color="blue darken-4" outlined
													><ShowValue :object="credential" object-key="access" label="access"></ShowValue
												></v-chip>
											</p> -->
										</td>
										<td class="p-2 border-top-light-grey custome-table-td">
											<v-chip
												v-if="getValue(credential, 'updated_at') || getValue(credential, 'added_at')"
												color="amber darken-4"
												outlined
											>
												<template v-if="getValue(credential, 'updated_at')">
													{{ formatDate(getValue(credential, "updated_at")) }}
												</template>

												<template v-else>
													{{ formatDate(getValue(credential, "added_at")) }}
												</template>
											</v-chip>
										</td>
										<td class="p-2 border-top-light-grey custome-table-td">
											<template
												v-if="
													getValue(Login_User, 'is_master') == 1 &&
													(getPermission('overview_credentials:create') ||
														getPermission('overview_credentials:update')) &&
													!pageLoading
												"
											>
												<div class="d-flex justify-center">
													<v-tooltip left>
														<template #activator="{ on, attrs }">
															<v-icon
																v-on="on"
																v-bind="attrs"
																color="#0D47A1"
																@click="edit(credential, 'credential')"
																small
																:disabled="pageLoading"
																>edit</v-icon
															>
														</template>
														<span>Edit {{ credential.type }}</span>
													</v-tooltip>
												</div>
											</template>
										</td>
									</tr>
								</template>
								<!-- v-for="(credential, index) in credentialList" :key="`_list_data_${index}`" -->
								<!-- <tr>
							<th
								class="p-2 border-top-light-grey custome-table-td"
								style="border-top: solid 1px #e2e5ec !important;"
							>
								gsc
							</th>
							<td class="p-2 border-top-light-grey custome-table-td">@bthurst@gmail.com</td>
							<td class="p-2 border-top-light-grey custome-table-td">BThurst</td>
							<td class="p-2 border-top-light-grey custome-table-td">134</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div>
									<p><ShowValue :object="ga_credential" object-key="origin" label="origin"></ShowValue></p>
									<p class="py-0 mb-0">
										<ShowValue :object="ga_credential" object-key="access" label="access"></ShowValue>
									</p>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue
									:object="ga_credential"
									object-key="credentials_date"
									label="credentials_date"
								></ShowValue>
							</td>
						</tr>
						<tr>
							<th
								class="p-2 border-top-light-grey custome-table-td"
								style="border-top: solid 1px #e2e5ec !important"
							>
								GA
							</th>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="ga_credential" object-key="email" label="email"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="ga_credential" object-key="username" label="username"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="ga_credential" object-key="password" label="password"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div>
									<p><ShowValue :object="ga_credential" object-key="origin" label="origin"></ShowValue></p>
									<p class="py-0 mb-0">
										<ShowValue :object="ga_credential" object-key="access" label="access"></ShowValue>
									</p>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue
									:object="ga_credential"
									object-key="credentials_date"
									label="credentials_date"
								></ShowValue>
							</td>
						</tr>
						<tr>
							<th
								class="p-2 border-top-light-grey custome-table-td"
								style="border-top: solid 1px #e2e5ec !important"
							>
								CMS
							</th>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="cms_credential" object-key="email" label="email"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="cms_credential" object-key="username" label="username"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="cms_credential" object-key="password" label="password"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div>
									<p><ShowValue :object="ga_credential" object-key="origin" label="origin"></ShowValue></p>
									<p class="py-0 mb-0">
										<ShowValue :object="ga_credential" object-key="access" label="access"></ShowValue>
									</p>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue
									:object="ga_credential"
									object-key="credentials_date"
									label="credentials_date"
								></ShowValue>
							</td>
						</tr>
						<tr>
							<th
								class="p-2 border-top-light-grey custome-table-td"
								style="border-top: solid 1px #e2e5ec !important"
							>
								Panel
							</th>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="panel_credential" object-key="email" label="email"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="panel_credential" object-key="username" label="username"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="panel_credential" object-key="password" label="password"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div>
									<p><ShowValue :object="ga_credential" object-key="origin" label="origin"></ShowValue></p>
									<p class="py-0 mb-0">
										<ShowValue :object="ga_credential" object-key="access" label="access"></ShowValue>
									</p>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue
									:object="ga_credential"
									object-key="credentials_date"
									label="credentials_date"
								></ShowValue>
							</td>
						</tr>
						<tr>
							<th
								class="p-2 border-top-light-grey custome-table-td"
								style="border-top: solid 1px #e2e5ec !important"
							>
								FTP
							</th>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="ftp_credential" object-key="email" label="email"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="ftp_credential" object-key="username" label="username"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="ftp_credential" object-key="password" label="password"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div>
									<p><ShowValue :object="ga_credential" object-key="origin" label="origin"></ShowValue></p>
									<p class="py-0 mb-0">
										<ShowValue :object="ga_credential" object-key="access" label="access"></ShowValue>
									</p>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue
									:object="ga_credential"
									object-key="credentials_date"
									label="credentials_date"
								></ShowValue>
							</td>
						</tr> -->
							</tbody>
							<tfoot v-if="data.credential.length == 0">
								<tr>
									<td colspan="7">
										<p class="m-0 row-not-found text-center">
											<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
											Uhh... There are no credentials at the moment.
										</p>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</v-col>
		</v-row>

		<!-- Promised KPI -->
		<Dialog
			v-if="
				getValue(Login_User, 'is_master') == 1 &&
				(getPermission('promised_kpi_customer:create') ||
					getPermission('promised_kpi_customer:update') ||
					getPermission('promised_kpi_delhi:create') ||
					getPermission('promised_kpi_delhi:update'))
			"
			:dialog="dialogs.kpi"
			:dialog-width="600"
			@close="closeModal"
		>
			<template v-slot:title> Edit Promised KPI</template>
			<template v-slot:body>
				<v-row>
					<v-col
						v-if="
							kpiType == 1 &&
							(getPermission('promised_kpi_customer:create') ||
								getPermission('promised_kpi_customer:update'))
						"
						md="12"
					>
						<div class="font-level-3-bold grey lighten-3 pl-1 py-1">To Customer</div>
						<v-row>
							<v-col md="2"></v-col>
							<v-col md="5" class="fw-600 text-center">6 Month</v-col>
							<v-col md="5" class="fw-600 text-center">12 Month</v-col>
						</v-row>

						<div class="d-flex justify-space-around">
							<div class="d-flex flex-column">
								<template v-for="(key, index) in full_year_keys">
									<label class="my-3" :key="'toCustomer_' + index">{{ mod_string(key, "_", " ") }}</label>
								</template>
							</div>
							<div class="d-flex flex-column justify-center align-center">
								<template v-for="(key, index) in half_year_keys">
									<TextInput
										:key="index"
										hide-details
										v-model="toEdit.kpi['toCustomer'][key]"
										style="margin-top: 4px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										:placeholder="mod_string(key, '_', ' ')"
										@input="inputValue($event, 'toCustomer', key)"
									></TextInput>
								</template>
							</div>
							<div class="d-flex flex-column justify-center align-center">
								<template v-for="(key, index) in full_year_keys">
									<TextInput
										:key="index"
										hide-details
										hideTopMargin
										v-model="toEdit.kpi['toCustomer'][key]"
										style="margin-top: 4px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										@keydown="inputValue($event, 'toCustomer', key)"
										:placeholder="mod_string(key, '_', ' ')"
									></TextInput>
								</template>
							</div>
						</div>
					</v-col>
					<v-col
						v-else-if="
							kpiType == 2 &&
							(getPermission('promised_kpi_delhi:create') || getPermission('promised_kpi_delhi:update'))
						"
						md="12"
					>
						<div class="font-level-3-bold grey lighten-3 pl-1 py-1">To Delhi</div>
						<v-row>
							<v-col md="2"></v-col>
							<v-col md="5" class="fw-600 text-center">6 Month</v-col>
							<v-col md="5" class="fw-600 text-center">12 Month</v-col>
						</v-row>
						<div class="d-flex justify-space-around">
							<div class="d-flex flex-column">
								<template v-for="(key, index) in full_year_keys">
									<label class="my-3" :key="'toDelhi_' + index">{{ mod_string(key, "_", " ") }}</label>
								</template>
							</div>
							<div class="d-flex flex-column justify-center align-center">
								<template v-for="(key, index) in half_year_keys">
									<TextInput
										:key="index"
										hide-details
										v-model="toEdit.kpi['toDelhi'][key]"
										style="margin-top: 4px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										:placeholder="mod_string(key, '_', ' ')"
										@keydown="inputValue($event, 'toDelhi', key)"
									></TextInput>
								</template>
							</div>
							<div class="d-flex flex-column justify-center align-center">
								<template v-for="(key, index) in full_year_keys">
									<TextInput
										:key="index"
										hide-details
										hideTopMargin
										v-model="toEdit.kpi['toDelhi'][key]"
										style="margin-top: 4px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										:placeholder="mod_string(key, '_', ' ')"
										@keydown="inputValue($event, 'toDelhi', key)"
									></TextInput>
								</template>
							</div>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="update"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>

		<!-- ABOUT -->
		<Dialog
			v-if="
				getValue(Login_User, 'is_master') == 1 &&
				(getPermission('overview_about:create') || getPermission('overview_about:update'))
			"
			:dialog="dialogs.about"
			:dialog-width="600"
			@close="closeModal"
		>
			<template v-slot:title>Edit ABOUT</template>
			<template v-slot:body>
				<v-form v-model="aboutFormValid">
					<v-row>
						<v-col md="12">
							<v-row>
								<v-col md="3">
									<label class="">Business Name</label>
								</v-col>
								<v-col md="9">
									<TextInput
										hide-details
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										v-model="toEdit.about.business_name"
										:loading="pageLoading"
										placeholder="Business Name"
									></TextInput>
								</v-col>
								<v-col md="3">
									<label class="">Phone</label>
								</v-col>
								<v-col md="9">
									<PhoneTemplate
										hide-details
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										@loading="($event) => (pageLoading = $event)"
										@clear="toEdit.about.phone = null"
										@validity="aboutFormValid = $event"
										v-model="toEdit.about.phone"
										hideBottomMargin
										placeholder="Phone"
									></PhoneTemplate>
								</v-col>
								<v-col md="3">
									<label class="">Email</label>
								</v-col>
								<v-col md="9">
									<EmailInput
										hide-details
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="toEdit.about.email"
										placeholder="Email"
									></EmailInput>
								</v-col>
								<v-col md="3">
									<label class="">Address</label>
								</v-col>
								<v-col md="9">
									<TextInput
										hide-details
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="toEdit.about.address"
										placeholder="Address"
									></TextInput>
								</v-col>
								<v-col md="3">
									<label class="">CMS</label>
								</v-col>
								<v-col md="9">
									<AutoCompleteInput
										hide-details
										hideTopMargin
										:items.sync="list.cmsList"
										v-model="toEdit.about.CMS"
										placeholder="CMS"
									></AutoCompleteInput>
									<!-- @change="otherCMS" -->
									<!-- item-text="display_name" -->
									<!-- v-model="toEdit.teams.sales_owner" -->
								</v-col>

								<v-col md="3" v-if="toEdit.about.CMS == 'other'"></v-col>

								<v-col md="9" v-if="toEdit.about.CMS == 'other'">
									<TextInput
										hide-details
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="toEdit.about.other_cms"
										placeholder="Other CMS"
									></TextInput>
								</v-col>

								<v-col md="3">
									<label class="">Account Country</label>
								</v-col>
								<v-col md="9">
									<AutoCompleteInput
										hide-details
										:items.sync="list.countryList"
										hideTopMargin
										v-model="toEdit.about.account_country"
										placeholder="Account Country"
									></AutoCompleteInput>
									<!-- item-text="display_name" -->
									<!-- v-model="toEdit.teams.sales_owner" -->
								</v-col>
								<v-col md="3">
									<label class="">Category</label>
								</v-col>
								<v-col md="9">
									<AutoCompleteInput
										hide-details
										:items.sync="list.categoryList"
										hideTopMargin
										item-text="category"
										item-value="value"
										v-model="toEdit.about.category"
										placeholder="Category"
									></AutoCompleteInput>
								</v-col>
								<!-- <v-col md="3">
								<label class="">Target Country</label>
							</v-col>
							<v-col md="9">
								<AutoCompleteInput
									hide-details
									:items.sync="list.countryList"
									hideTopMargin
									v-model="toEdit.about.target_country"
									placeholder="Target Country"
								></AutoCompleteInput>
							</v-col> -->
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="update"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !aboutFormValid"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>

		<!-- Edit Team -->
		<Dialog
			v-if="
				getValue(Login_User, 'is_master') == 1 &&
				(getPermission('overview_team:create') || getPermission('overview_team:update'))
			"
			:dialog="dialogs.teams"
			:dialog-width="600"
			@close="closeModal"
		>
			<template v-slot:title> Edit Team</template>
			<template v-slot:body>
				<v-row>
					<v-col md="12">
						<v-row>
							<v-col md="4">
								<label class="mt-1">Sales Owner</label>
							</v-col>
							<v-col md="8" class="mb-1">
								<!-- <AutoCompleteInput
									hide-details
									hideTopMargin
									:items.sync="data.role.sales_owner"
									item-text="display_name"
									item-value="id"
									readonly
									v-model="toEdit.teams.sales_owner"
									placeholder="Sales Owner"
								></AutoCompleteInput> -->

								<TextInput
									hide-details
									style="margin-top: 0px !important"
									:disabled="pageLoading"
									v-model="detail.project.sales_person.display_name"
									readonly
									prepend-inner-icon="mdi-lock"
									:loading="pageLoading"
									placeholder="Sales Owner"
								></TextInput>
								<!-- :id="`address-country-${stringId}`"
									
									v-model="address.address_country"
									v-on:change="getStates"
									:disabled="countryLoading || disabled"
									:loading="countryLoading || loading" -->
							</v-col>
							<v-col md="4">
								<label class="mt-1">Team Lead</label>
							</v-col>
							<v-col md="8" class="mb-1">
								<AutoCompleteInput
									hide-details
									hideTopMargin
									:items.sync="data.role.team_lead"
									:readonly="!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin'])"
									item-text="display_name"
									item-value="id"
									placeholder="Team Lead"
									:prepend-inner-icon="
										!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin'])
											? 'mdi-lock'
											: undefined
									"
									v-model="toEdit.teams.team_lead"
								></AutoCompleteInput>
							</v-col>
							<template v-if="usersType == 'seo' || getValue(Login_User, 'is_master') == 1">
								<v-col md="4">
									<label class="mt-1">A.Team Lead</label>
								</v-col>
								<v-col md="8" class="mb-1">
									<AutoCompleteInput
										hide-details
										hideTopMargin
										:items.sync="data.role.a_team_lead"
										:readonly="
											!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
										"
										item-text="display_name"
										item-value="id"
										placeholder="A.Team Lead"
										:prepend-inner-icon="
											!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
												? 'mdi-lock'
												: undefined
										"
										v-model="toEdit.teams.a_team_lead"
									></AutoCompleteInput>
								</v-col>
								<v-col md="4">
									<label class="mt-1">ON-PAGE</label>
								</v-col>
								<v-col md="8" class="mb-1">
									<AutoCompleteInput
										hide-details
										hideTopMargin
										:items.sync="data.role.on_page"
										item-text="display_name"
										item-value="id"
										placeholder="On Page"
										v-model="toEdit.teams.on_page"
									></AutoCompleteInput>
								</v-col>
								<v-col md="4">
									<label class="mt-1">OFF-PAGE</label>
								</v-col>
								<v-col md="8" class="mb-1">
									<AutoCompleteInput
										hide-details
										hideTopMargin
										:items.sync="data.role.off_page"
										item-text="display_name"
										item-value="id"
										placeholder="Off Page"
										v-model="toEdit.teams.off_page"
									></AutoCompleteInput>
								</v-col>
							</template>
							<v-col md="4">
								<label class="mt-1">Sales Coordinator</label>
							</v-col>
							<v-col md="8">
								<AutoCompleteInput
									hide-details
									hideTopMargin
									:readonly="
										!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
									"
									:items.sync="data.role.sales_coordinator"
									item-text="display_name"
									item-value="id"
									placeholder="Sales Coordinator"
									:prepend-inner-icon="
										!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
											? 'mdi-lock'
											: undefined
									"
									v-model="toEdit.teams.sales_coordinater"
								></AutoCompleteInput>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="update"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>

		<!-- Credentials -->
		<Dialog
			v-if="
				getValue(Login_User, 'is_master') == 1 &&
				(getPermission('overview_credentials:create') || getPermission('overview_credentials:update'))
			"
			:dialog="dialogs.credential"
			:dialog-width="600"
			@close="closeModal"
		>
			<template v-slot:title> {{ addNew ? "Add" : "Edit" }} Credentials</template>
			<template v-slot:body>
				<v-row>
					<v-col md="12">
						<v-form v-model.trim="formCredentials" @submit.stop.prevent="update">
							<v-row>
								<v-col md="4">
									<label class="mt-1 required">Type</label>
								</v-col>
								<v-col md="8">
									<!-- <TextInput
										hide-details
										v-model="toEdit.credential.type"
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										placeholder="Sales Owner"
									></TextInput> -->

									<AutoCompleteInput
										hideTopMargin
										hide-details
										:readonly="!addNew"
										v-model="toEdit.credential.type"
										:items.sync="addCredentialsStatus.filter_arr"
										placeholder="Type"
										:rules="[vrules.required(toEdit.credential.type, 'Type')]"
										:class="{
											required: !toEdit.credential.type,
										}"
									></AutoCompleteInput>
									<!-- item-text="display_name"
										item-value="id" -->
								</v-col>
								<v-col md="4">
									<label class="mt-1">Email</label>
								</v-col>
								<v-col md="8">
									<TextInput
										hide-details
										v-model="toEdit.credential.email"
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										placeholder="Email"
										:rules="[vrules.validEmail(toEdit.credential.email, 'Email')]"
									></TextInput>
								</v-col>
								<v-col md="4">
									<label class="mt-1 required">Username</label>
								</v-col>
								<v-col md="8">
									<TextInput
										hide-details
										v-model="toEdit.credential.username"
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										placeholder="Username"
										:rules="[vrules.required(toEdit.credential.username, 'Username')]"
										:class="{
											required: !toEdit.credential.username,
										}"
									></TextInput>
								</v-col>

								<v-col md="4">
									<label class="mt-1 required">Password</label>
								</v-col>
								<v-col md="8">
									<TextInput
										hide-details
										v-model="toEdit.credential.password"
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										placeholder="Password"
										:rules="[vrules.required(toEdit.credential.password, 'Password')]"
										:class="{
											required: !toEdit.credential.password,
										}"
										@keypress="limitDefine"
									></TextInput>
								</v-col>

								<template v-if="toEdit.credential.type == 'GA'">
									<v-col md="4">
										<label class="mt-1 required">GA API ID (E-Mail)</label>
									</v-col>
									<v-col md="8">
										<AutoCompleteInput
											hide-details
											hide-top-margin
											v-model="toEdit.credential.email_service_account"
											:items.sync="GA_EmailsList"
											:disabled="pageLoading"
											noStyleList
											:loading="pageLoading"
											placeholder="GA API ID (E-Mail)"
											:rules="[
												vrules.required(toEdit.credential.email_service_account, 'GA API ID (E-Mail)'),
												vrules.validEmail(toEdit.credential.email_service_account, 'GA API ID (E-Mail)'),
											]"
											:class="{
												required: !toEdit.credential.email_service_account,
											}"
										></AutoCompleteInput>
									</v-col>
								</template>

								<template v-if="toEdit.credential.type == 'GA'">
									<v-col md="4">
										<label class="mt-1 required">Property ID</label>
									</v-col>
									<v-col md="8">
										<TextInput
											hide-details
											v-model="toEdit.credential.property_id"
											style="margin-top: 0px !important"
											:disabled="pageLoading"
											:loading="pageLoading"
											placeholder="Property ID"
											:rules="[vrules.required(toEdit.credential.property_id, 'Property ID')]"
											:class="{
												required: !toEdit.credential.property_id,
											}"
										></TextInput>
									</v-col>
								</template>

								<template v-if="toEdit.credential.type == 'GSC'">
									<v-col md="4">
										<label class="mt-1 required">GSC API ID (E-Mail)</label>
									</v-col>
									<v-col md="8">
										<AutoCompleteInput
											hide-details
											hide-top-margin
											:items.sync="GSC_EmailsList"
											noStyleList
											v-model="toEdit.credential.email_service_account"
											:disabled="pageLoading"
											:loading="pageLoading"
											placeholder="GSC API ID (E-Mail)"
											:rules="[
												vrules.required(toEdit.credential.email_service_account, 'GSC API ID (E-Mail)'),
												vrules.validEmail(toEdit.credential.email_service_account, 'GSC API ID (E-Mail)'),
											]"
											:class="{
												required: !toEdit.credential.email_service_account,
											}"
										></AutoCompleteInput>
									</v-col>
								</template>

								<template
									v-if="
										toEdit.credential.type == 'CMS' ||
										toEdit.credential.type == 'FTP' ||
										toEdit.credential.type == 'Panel'
									"
								>
									<v-col md="4">
										<label class="mt-1 required">CMS Type</label>
									</v-col>
									<v-col md="8">
										<TextInput
											hide-details
											v-model="toEdit.credential.cms_type"
											style="margin-top: 0px !important"
											:disabled="pageLoading"
											:loading="pageLoading"
											placeholder="CMS Type"
											:rules="[vrules.required(toEdit.credential.cms_type, 'CMS Type')]"
											:class="{
												required: !toEdit.credential.cms_type,
											}"
										></TextInput>
									</v-col>
								</template>

								<template
									v-if="
										toEdit.credential.type == 'CMS' ||
										toEdit.credential.type == 'FTP' ||
										toEdit.credential.type == 'Panel'
									"
								>
									<v-col md="4">
										<label class="mt-1 required">Login URL</label>
									</v-col>
									<v-col md="8">
										<TextInput
											hide-details
											v-model="toEdit.credential.login_url"
											style="margin-top: 0px !important"
											:disabled="pageLoading"
											:loading="pageLoading"
											placeholder="Login URL"
											:rules="[
												vrules.required(toEdit.credential.login_url, 'Login URL'),
												vrules.validUrl(toEdit.credential.login_url, 'Login URL'),
											]"
											:class="{
												required: !toEdit.credential.login_url,
											}"
										></TextInput>
									</v-col>
								</template>
								<!-- <v-col md="4">
									<label class="mt-1">OFF-PAGE</label>
								</v-col>
								<v-col md="8">
									<TextInput
										hide-details
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										placeholder="OFF-PAGE"
									></TextInput>
								</v-col>
								<v-col md="4">
									<label class="mt-1">Sales Coordinator</label>
								</v-col>
								<v-col md="8">
									<TextInput
										hide-details
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										placeholder="Sales Coordinator"
									></TextInput>
								</v-col> -->
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="update"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !formCredentials"
				>
					Save
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>

		<!-- Competitors -->
		<Dialog
			v-if="
				getValue(Login_User, 'is_master') == 1 &&
				(getPermission('overview_competitors:create') || getPermission('overview_competitors:update'))
			"
			:dialog="dialogs.competitors"
			:dialog-width="600"
			@close="closeModal"
		>
			<template v-slot:title> Edit Competitors</template>
			<template v-slot:body>
				<v-form
					ref="customer_submit"
					v-model.trim="competitorFormValid"
					lazy-validation
					v-on:submit.stop.prevent="update"
				>
					<v-row>
						<v-col md="12">
							<v-row v-for="(comp, index) in data.competitors" :key="'comp_' + index">
								<v-col md="1">
									<label class="mt-1">{{ index + 1 }}</label>
								</v-col>
								<v-col md="11">
									<TextInput
										hide-details
										v-model="toEdit.competitors[index]"
										style="margin-top: 0px !important"
										:disabled="pageLoading"
										:loading="pageLoading"
										:rules="[vrules.validUrl(toEdit.competitors[index], `Competitor ${index + 1}`)]"
										placeholder="Domain or URL"
									></TextInput>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="update"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !competitorFormValid"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
// <style src="@/assets/sass/sprite.scss" lang="scss"></style>
import ShowValue from "@/view/components/ShowValue.vue";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import PhoneTemplate from "@/view/components/Phone";
import EmailInput from "@/view/components/EmailInput";
// import DatePicker from "@/view/components/DatePicker";
import SelectInput from "@/view/components/SelectInput";
/* import ApiService from "@/core/services/api.service"; */
import { cloneDeep } from "lodash";
// import { toSafeInteger } from "lodash";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import objectPath from "object-path";
import MainMixin from "@/core/mixins/main.mixin.js";
import { toString /* , parseInt */ } from "lodash";
import { GET, PUT, POST } from "@/core/services/store/request.module";
import { getCurrentUser } from "@/core/services/jwt.service";

export default {
	name: "Overview-Tab-Sales-Accounts",
	mixins: [MainMixin],
	props: {
		credentialStatus: {
			type: Boolean,
			default: true,
		},
		seoId: {
			type: Number,
			//required: true,
			default: 0,
		},
		projectId: {
			type: Number,
			//required: true,
			default: 0,
		},
		urlData: {
			type: Object,
			default: () => {},
		},
		salesPerson: {
			type: Object,
			default: null,
		},
		detail: {
			type: Object,
			default: () => {},
		},
		usersType: {
			type: String,
		},
	},
	watch: {
		salesPerson: {
			deep: true,
			immediate: true,
			handler(param) {
				this.data["teams"].sales_owner_name = param.display_name;
				this.data["teams"].sales_owner = param.id;
			},
		},
	},
	components: {
		ShowValue,
		// DatePicker,
		Dialog,
		TextInput,
		PhoneTemplate,
		EmailInput,
		SelectInput,
		AutoCompleteInput,
	},
	data() {
		return {
			pageLoading: false,
			competitorFormValid: true,
			aboutDialog: false,
			kpiDialog: false,
			teamDialog: false,
			competitorsDialog: false,
			credentialsDialog: false,

			about_tab: true,
			contract_tab: true,
			team_tab: true,
			promised_kpi: true,
			competitors_tab: true,
			credentials_tab: true,

			list: {
				opportunityList: null,
				statusList: null,
				sourceList: null,
				productList: null,
				userList: null,
				countryList: null,
				categoryList: [
					{ text: "Category 1", value: "category1" },
					{ text: "Category 2", value: "category2" },
				],

				cmsList: [
					{ text: "Shopify", value: "shopify", profile_pic: "media/custom-svg/wix-cms.svg" },
					{ text: "Wix", value: "wix", profile_pic: "media/custom-svg/wordpress-cms.svg" },
					{ text: "Wordpress", value: "wordpress", profile_pic: "media/custom-svg/shopify-cms.svg" },
					{ text: "Other", value: "other", profile_pic: "" },
				],
			},
			aboutFormValid: false,

			// detail: {},
			reporting_edit: false,

			domainDetail: {
				business_name: "Fox Company",
				phone: "9368654504",
				email: "krishan.kumar@businessthrust.com",
				address: "address 1 MZn 25001",
				cms: "shopify",
				category: "Clinic",
				target_country: "MY",
				account_country: "MY",
				contract_start_date: "2023-07-29 10:40:34",
				contract_end_date: "2023-08-27 10:40:34",
				reporting_date: "2023-10-14 10:40:34",
				sales_owner: "Mr Ajay",
				team_lead: "Mr Jhon Paul",
				assistance_team_lead: "",
				on_page: "Vivek Singh",
				off_page: "Lovekush Kumar",
				sales_coordinator: "Terrence",
				months_pass: 5,
				months_rem: 7,
			},

			addNew: false,
			toAddCred: null,

			credentialcheckList: ["Off Page", "GSC", "GA", "CMS", "Panel", "FTP"],

			half_year_keys: [],
			full_year_keys: [],

			formCredentials: true,

			data: {
				credential: [],
				teams: {
					seo_id: null,
					project_id: null,
					sales_owner: null,
					team_lead: null,
					a_team_lead: null,
					on_page: null,
					off_page: null,
					sales_coordinater: null,
					sales_owner_name: null,
					team_lead_name: null,
					a_team_lead_name: null,
					on_page_name: null,
					off_page_name: null,
					sales_coordinater_name: null,
				},
				competitors: ["Competitor1", "Competitor2", "Competitor3", "Competitor4", "Competitor5"],
				kpi: {
					toCustomer: {
						top_3: null,
						top_5: null,
						top_10: null,
						top_20: null,
						top_50: null,
						top_100: null,
						kpi_6_month_top_3: null,
						kpi_6_month_top_5: null,
						kpi_6_month_top_10: null,
						kpi_6_month_top_20: null,
						kpi_6_month_top_50: null,
						kpi_6_month_top_100: null,
					},
					toDelhi: {
						top_3: null,
						top_5: null,
						top_10: null,
						top_20: null,
						top_50: null,
						top_100: null,
						kpi_6_month_top_3: null,
						kpi_6_month_top_5: null,
						kpi_6_month_top_10: null,
						kpi_6_month_top_20: null,
						kpi_6_month_top_50: null,
						kpi_6_month_top_100: null,
					},
				},
				about: {
					business_name: null,
					phone: null,
					email: null,
					address: null,
					CMS: null,
					account_country: null,
					category: null,
					target_country: null,
					other_cms: null,
				},
				role: {
					team_lead: [],
					a_team_lead: [],
					on_page: [],
					off_page: [],
					sales_owner: [],
					sales_coordinator: [],
				},
			},

			kpiType: null,
			// detail: {},
			editModule: null,

			toEdit: {
				about: {
					business_name: null,
					phone: null,
					email: null,
					address: null,
					CMS: null,
					other_cms: null,
					account_country: null,
					category: null,
					target_country: null,
				},

				credential: {
					type: null,
					email: null,
					username: null,
					password: null,
					email_service_account: null,
					property_id: null,
					cms_type: null,
					login_url: null,
				},

				teams: {
					sales_owner: null,
					team_lead: null,
					a_team_lead: null,
					on_page: null,
					off_page: null,
					sales_coordinater: null,
					sales_owner_name: null,
					team_lead_name: null,
					a_team_lead_name: null,
					on_page_name: null,
					off_page_name: null,
					sales_coordinater_name: null,
				},
				competitors: [],
				kpi: {
					toCustomer: {
						top_3: null,
						top_5: null,
						top_10: null,
						top_20: null,
						top_50: null,
						top_100: null,
						kpi_6_month_top_3: null,
						kpi_6_month_top_5: null,
						kpi_6_month_top_10: null,
						kpi_6_month_top_20: null,
						kpi_6_month_top_50: null,
						kpi_6_month_top_100: null,
					},
					toDelhi: {
						top_3: null,
						top_5: null,
						top_10: null,
						top_20: null,
						top_50: null,
						top_100: null,
						kpi_6_month_top_3: null,
						kpi_6_month_top_5: null,
						kpi_6_month_top_10: null,
						kpi_6_month_top_20: null,
						kpi_6_month_top_50: null,
						kpi_6_month_top_100: null,
					},
				},
			},
			dialogs: {
				about: false,
				kpi: false,
				credential: false,
				competitors: false,
				teams: false,
			},
			update_apis: {
				about: "update-overview",
				kpi: "seocustomer",
				credential: "credentials",
				competitors: "competitors",
				teams: "seoteams",
			},
			get_apis: {
				about: "seo-overview",
				kpi: `getseocustomer`,
				credential: `credentials`,
				competitors: `competitors`,
				teams: `seo-team`,
				role: "user-role",
			},
			current_rank: {},
			createAbout: false,
			country_flag: {
				china: "cn",
				singapore: "sg",
				malaysia: "my",
				myanmar: "mm",
				australia: "au",
				usa: "us",
				uk: "gb",
				canada: "ca",
			},
			domainUrls: [
				(v) => {
					let regex =
						/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
					return v ? regex.test(v) || "Domain must be valid" : false;
				},
			],
			GSC_EmailsList: [],
			GA_EmailsList: [],
			Login_User: null,
			BG_Color: {
				1: "bg-green",
				2: "bg-orange",
				3: "bg-red",
				empty: "bg-white",
			},
			Approval_Status_Colors: {
				0: "orange--text",
				1: "green--text",
				2: "red--text",
			},
			Approval_Status_Title: {
				0: "Pending",
				1: "Approved",
				2: "Rejected",
			},
			Requested_Status: {
				1: "(Active)",
				2: "(Hold)",
				3: "(Close)",
			},
			R_Approval_Status_Colors: {
				1: "green--text",
				2: "orange--text",
				3: "red--text",
			},
		};
	},
	computed: {
		addCredentialsStatus: {
			get() {
				let map_arr, filter_arr, filter_arr1, check_len;
				map_arr = this.data.credential.map((v) => v.type);
				filter_arr = this.credentialcheckList.filter((v) => map_arr.includes(v));
				filter_arr1 = this.credentialcheckList.filter((v) => !map_arr.includes(v));
				check_len = this.credentialcheckList.length;
				return {
					filter_arr: this.toAddCred ? filter_arr1.concat([this.toAddCred]) : [...filter_arr1],
					status: filter_arr.length === check_len,
				};
			},
		},
		...mapGetters(["currentUserPermissions"]),
	},
	methods: {
		hasKey(obj, key) {
			return objectPath.has(obj, key);
		},
		getValue(obj, key) {
			return objectPath.get(obj, key);
		},
		ATLPermitCredentials(cred, type) {
			if (type == "data") {
				return cred.type == "Off Page" || cred.type == "GA" || cred.type == "GSC";
			} else {
				return cred == "Off Page" || cred == "GA" || cred == "GSC";
			}
		},
		inputValue(event, type, key) {
			/* let val = event; */
			let value = this.toEdit.kpi[type][key];
			/* let charCode = val.charCode || val.which; */
			// console.log("event.target.value => ", charCode, parseInt(value), parseInt(value) <= 100);

			if (
				(Boolean(+value) || Boolean(+event)) &&
				((+value >= 0 && +value <= 100) || (+event >= 0 && +event <= 100))
			) {
				return false;
			} else {
				this.toEdit.kpi[type][key] = 0;
			}

			/* if (
				((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)) &&
				parseInt(value) &&
				parseInt(value) <= 100
			) {
				console.log("return false");
				return false;
			} else {
				console.log("event.preventDefault();");
				event.preventDefault();
			} */
		},
		/* otherCMS(value) {
			console.log({ value });
		}, */
		copyToClipboard(text) {
			navigator.clipboard.writeText(text).then(
				() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: `Success ! ${text} copied successfully.`,
						},
					]);
				},
				() => {
					console.log("Text cannot copied");
				}
			);
		},
		kpiColorIdentifier(half_yr, full_yr, current) {
			let monthPassed = this.detail.month_passed;
			let kpi_value;
			if (monthPassed <= 6) {
				kpi_value = half_yr * (monthPassed / 6);
			} else {
				kpi_value = full_yr * (monthPassed / 12);
			}

			return Math.ceil(kpi_value) <= current ? "green--text" : "red--text";
		},
		onEscape() {
			this.closeModal();
		},
		edit(data, module, type = 0) {
			this.editModule = module;
			this.kpiType = type > 0 ? type : null;
			if (module == "credential") {
				this.toAddCred = "type" in data ? data?.type : null;
			}

			let dataToEdit;

			if (data && Object.keys(data).length == 0) {
				this.addNew = true;
			}

			if (module == "competitors") {
				dataToEdit = [...data];
				this.toEdit[module] = [...dataToEdit];
			} else if (module == "kpi") {
				let half_year = Object.keys(data).filter((v) => v.includes("kpi"));
				let full_year = Object.keys(data).filter((v) => !v.includes("kpi") && v.includes("top"));
				this.half_year_keys = [...half_year];
				this.full_year_keys = [...full_year];

				dataToEdit = {
					top_3: data?.top_3 || 0,
					top_5: data?.top_5 || 0,
					top_10: data?.top_10 || 0,
					top_20: data?.top_20 || 0,
					top_50: data?.top_50 || 0,
					top_100: data?.top_100 || 0,
					kpi_6_month_top_3: data?.kpi_6_month_top_3 || 0,
					kpi_6_month_top_5: data?.kpi_6_month_top_5 || 0,
					kpi_6_month_top_10: data?.kpi_6_month_top_10 || 0,
					kpi_6_month_top_20: data?.kpi_6_month_top_20 || 0,
					kpi_6_month_top_50: data?.kpi_6_month_top_50 || 0,
					kpi_6_month_top_100: data?.kpi_6_month_top_100 || 0,
				};
				const cloneData = cloneDeep(dataToEdit);
				this.toEdit[module][type == 1 ? "toCustomer" : "toDelhi"] = { ...cloneData };
			} else {
				dataToEdit = { ...data };
				const cloneData = cloneDeep(dataToEdit);
				this.toEdit[module] = { ...cloneData };
			}

			this.dialogs[module] = true;

			// if (module == "credential") {
			// 	this.toEdit[module].type = dataToEdit.type;
			// 	this.toEdit[module].email = dataToEdit.email;
			// 	this.toEdit[module].username = dataToEdit.username;
			// 	this.toEdit[module].password = dataToEdit.password;
			// } else if (module == "teams") {
			// 	this.toEdit[module].sales_owner = dataToEdit.sales_owner;
			// 	this.toEdit[module].team_lead = dataToEdit.team_lead;
			// 	this.toEdit[module].a_team_lead = dataToEdit.a_team_lead;
			// 	this.toEdit[module].on_page = dataToEdit.on_page;
			// 	this.toEdit[module].off_page = dataToEdit.off_page;
			// 	this.toEdit[module].sales_coordinater = dataToEdit.sales_coordinater;
			// }
		},
		mod_string(text, saperator = "", joiner = "") {
			let splitArr = text ? text.split(saperator) : [];
			let mod_arr = splitArr.map((v) => v.charAt(0).toUpperCase() + v.slice(1));
			return mod_arr.join(joiner);
		},
		update() {
			// const module = this.editModule;
			const project_id = this.projectId;
			const seo_id = this.seoId;
			let method = "post";
			let payload;

			if (this.editModule == "competitors") {
				let dataCheck = [...new Set(this.toEdit[this.editModule])];

				if (dataCheck.length != this.toEdit[this.editModule].length) {
					this.$store.commit(SET_ERROR, [
						{
							model: true,
							message: "Error: Duplicate competitors are not allowed.",
						},
					]);
					return false;
				}

				payload = { competitors: this.toEdit[this.editModule].join(), project_id, seo_id };
			} else if (this.editModule == "kpi") {
				payload = {
					...this.toEdit[this.editModule][this.kpiType == 1 ? "toCustomer" : "toDelhi"],
					project_id,
					seo_id,
					type: this.kpiType,
				};
			} else if (this.editModule == "about") {
				method = this.createAbout ? "put" : "put";
				payload = { ...this.toEdit[this.editModule], project_id, seo_id };
			} else {
				payload = { ...this.toEdit[this.editModule], project_id, seo_id };
			}

			const url = this.update_apis[this.editModule];

			this.pageLoading = true;
			this.$store
				.dispatch(method == "post" ? POST : PUT, { url, data: payload })
				.then(() => {
					this.getAllData(this.editModule);
					if (this.editModule == "kpi" || this.editModule == "teams") {
						this.$emit("refresh", true);
					}
					if (this.editModule == "teams") {
						this.editModule = "team";
					}
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: `Success ! ${this.mod_string(this.editModule).toLowerCase()} ${
								objectPath.get(payload, "id") ||
								this.editModule == "team" ||
								this.editModule == "kpi" ||
								this.editModule == "competitors"
									? "updated"
									: "created"
							} successfully.`,
						},
					]);
					this.closeModal();
				})
				.catch((message) => {
					console.log({ message });
					/* this.$store.commit(SET_ERROR, [
						{
							model: true,
							message,
						},
					]); */
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeModal() {
			let reset_vals;

			if (this.editModule == "credential") {
				reset_vals = {
					type: null,
					email: null,
					username: null,
					password: null,
				};
			} else if (this.editModule == "teams") {
				reset_vals = {
					sales_owner: null,
					team_lead: null,
					a_team_lead: null,
					on_page: null,
					off_page: null,
					sales_coordinater: null,
				};
			} else if (this.editModule == "credential") {
				reset_vals = {
					type: null,
					email: null,
					username: null,
					password: null,
				};
			} else if (this.editModule == "kpi") {
				reset_vals = {
					toCustomer: {
						top_3: null,
						top_5: null,
						top_10: null,
						top_20: null,
						top_50: null,
						top_100: null,
					},
					toDelhi: {
						top_3: null,
						top_5: null,
						top_10: null,
						top_20: null,
						top_50: null,
						top_100: null,
					},
				};
			} else if (this.editModule == "competitors") {
				reset_vals = [];
			} else if (this.editModule == "about") {
				reset_vals = {
					business_name: null,
					phone: null,
					email: null,
					address: null,
					CMS: null,
					account_country: null,
					category: null,
					target_country: null,
					other_cms: null,
				};
			}

			this.toEdit[this.editModule] = cloneDeep(reset_vals);

			this.addNew = false;

			for (const key in this.dialogs) {
				this.dialogs[key] = false;
			}

			this.editModule = null;
			this.toAddCred = null;
		},
		getAllData(_key) {
			this.pageLoading = true;
			const forIteration = _key ? { [_key]: this.get_apis[_key] } : { ...this.get_apis };
			for (const key in forIteration) {
				let url;
				// let method = "get";
				if (key == "role") {
					url = this.get_apis[key];
				} else if (key == "credential" || key == "competitors" || key == "about") {
					url = this.get_apis[key] + `/${this.projectId}/${this.seoId}`;
				} else {
					url = this.get_apis[key] + `?project_id=${this.projectId}&seo_id=${this.seoId}`;
				}
				this.$store
					.dispatch(GET, { url })
					.then((data) => {
						if (key == "competitors") {
							this.data[key] = data ? data.competitors.split(",") : this.data[key];
						} else if (key == "kpi") {
							this.data[key]["toCustomer"] = data.to_customer
								? data.to_customer
								: this.data[key]["toCustomer"];
							this.data[key]["toDelhi"] = data.to_delhi ? data.to_delhi : this.data[key]["toDelhi"];
							this.current_rank = data.current_rank;
						} else if (key == "teams") {
							this.data[key] = data ? { ...data } : this.data[key];
						} else if (key == "credential") {
							this.createAbout =
								key == "about" && !data ? true : key == "about" && data ? false : this.createAbout;

							if (this.AllowAnyOforGetRoleNType(["ateam_lead", "on_page", "off_page"])) {
								/* ATL Role can only view and edit below credentials */
								let filteredData = data.filter((cred) => this.ATLPermitCredentials(cred, "data"));
								this.credentialcheckList = this.credentialcheckList.filter((cred) =>
									this.ATLPermitCredentials(cred, "dd")
								);
								this.data[key] = filteredData.length ? filteredData : [];
							} else {
								this.data[key] = data ? data : this.data[key];
							}
						} else {
							this.createAbout =
								key == "about" && !data ? true : key == "about" && data ? false : this.createAbout;
							this.data[key] = data ? data : this.data[key];
							/* console.log("DomainOverviewTab  ==> ", { data, key }); */
						}
					})
					.catch(({ data }) => {
						this.$store.commit(SET_ERROR, [
							{
								model: true,
								message: (data && data.message) || "Something went wrong",
							},
						]);
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}
			// this.pageLoading = false;
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "lead-setting" })
				.then((data) => {
					// _this.opportunityList = data.opportunity;
					// /* 	_this.statusList = data.status; */
					// let statusData = cloneDeep(data.status);
					// statusData.shift(1);
					// _this.statusList = statusData;
					// _this.sourceList = data.source;
					// _this.productList = data.product_type;
					// _this.userList = data.users;
					_this.list.countryList = data.country || _this.list.countryList;
					_this.list.categoryList = data.category || _this.list.categoryList;
					_this.list.cmsList = data?.cms
						? [...data?.cms, { text: "Other", value: "other", profile_pic: "" }]
						: _this.list.cmsList;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		clickoutside() {
			this.reporting_edit = false;
		},
		limitDefine(event) {
			let value = toString(event.target.value);
			if (value.length > 7) {
				event.preventDefault();
			}
		},
		update_reporting_date() {
			/* let method = "put", */
			let url = `seo/${this.seoId}/project/${this.projectId}/update-ranking-date`,
				payload = {
					date: this.detail.reporting_date.toString(),
				};

			this.$store
				.dispatch(PUT, { url, data: payload })
				// ApiService[method](url, payload)
				.then((data) => {
					console.log(data);
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: `Success ! ${this.mod_string(this.editModule)} updated successfully.`,
						},
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [
						{
							model: true,
							message: error,
						},
					]);
				});
		},
		getGoogleAnalyticsCredentials() {
			this.$store
				.dispatch(GET, { url: `analytic-credentials` })
				.then((data) => {
					if (data && Array.isArray(data) && data.length) {
						const EMAILS = data.map((data) => data.email).filter(Boolean);
						this.GA_EmailsList = [...EMAILS];
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getGoogleSearchCnsoleCredentials() {
			this.$store
				.dispatch(GET, { url: `gsc-credentials` })
				.then((data) => {
					if (data && Array.isArray(data) && data.length) {
						const EMAILS = data.map((data) => data.email).filter(Boolean);
						this.GSC_EmailsList = [...EMAILS];
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},

		// getSingleProject() {
		// 	let seoId = toSafeInteger(this.$route.params.id);
		// 	this.$store
		// 		.dispatch(GET, {
		// 			url: `seo/${seoId}`,
		// 		})
		// 		.then((data) => {
		// 			this.detail = { ...data };
		// 		})
		// 		.catch((error) => {
		// 			console.log({
		// 				error,
		// 			});
		// 		});
		// },
	},
	mounted() {
		this.getAllData();
		this.getSettings();
		this.getGoogleAnalyticsCredentials();
		this.getGoogleSearchCnsoleCredentials();

		const CURRENT_USER = getCurrentUser();
		this.Login_User = CURRENT_USER;

		/* if (this.checkAdminOrManager() == 9) {
			this.credentialcheckList =
		} */

		// this.getSingleProject();
		// this.detail = cloneDeep(this.detail);
	},
};
</script>
